<template>
  <div id="pkt-icons-sprite" style="display: none"><svg xmlns="http://www.w3.org/2000/svg">
<symbol id="chevron-down" fill="none" viewBox="0 0 32 32">
  <path fill="var(--fg-color, #2A2859)" fill-rule="evenodd"
    d="m15.9972 23.1429 10-10-4.2858-4.2858-5.7142 5.7143-5.7143-5.7143-4.2857 4.2858 10 10ZM10.2829 6l5.7143 5.7143L21.7114 6l7.1429 7.1429L15.9972 26 3.14 13.1429 10.283 6Z"
    clip-rule="evenodd" />
</symbol><symbol id="close" fill="none" viewBox="0 0 24 25">
  <path fill="var(--fg-color, #2A2859)" fill-rule="evenodd" d="M10.9 12.5 3.75 5.35l1.1-1.1L12 11.4l7.15-7.15 1.1 1.1-7.15 7.15 7.15 7.15-1.1 1.1L12 13.6l-7.15 7.15-1.1-1.1 7.15-7.15Z" clip-rule="evenodd"/>
</symbol>
<symbol id="download" fill="none" viewBox="0 0 32 32">
  <g fill="var(--fg-color, #2A2859)">
    <path d="m15 18.211-5.293-5.293-1.414 1.415L16 22.04l7.707-7.707-1.414-1.415L17 18.211V1h-2v17.21Z" />
    <path d="M3 29v-9.125H1V31h30V20h-2v9H3Z" />
    <path d="M23 26.25v-2.5H9v2.5h14Z" />
  </g>
</symbol><symbol id="expand" fill="none" viewBox="0 0 32 32">
  <g fill="var(--fg-color, #2A2859)">
    <path d="M4 4h2v8H4V4Zm24 24h-2v-8h2v8Zm0-24v2h-8V4h8ZM4 28v-2h8v2H4Z" />
    <path d="M12 4v2H4V4h8Zm8 24v-2h8v2h-8Zm8-16h-2V4h2v8ZM4 20h2v8H4v-8Z" />
  </g>
</symbol><symbol id="graph" fill="none" viewBox="0 0 32 32">
  <g fill="var(--fg-color, #2A2859)">
    <path d="M29 8V1h-7v2h3.596l-8.212 8.21-8.63-8.75-5.581 5.583 1.414 1.414L8.745 5.3l8.63 8.75L27 4.424V8h2Z" />
    <path fill-rule="evenodd"
      d="M10.27 13H3.1v16H1v2h30v-2h-2.11V14h-7.17v15h-2.14V18h-7.17v11h-2.14V13Zm13.45 16h3.17V16h-3.17v13Zm-6.14-9v9h-3.17v-9h3.17Zm-9.31 9V15H5.1v14h3.17Z"
      clip-rule="evenodd" />
  </g>
</symbol><symbol id="location-pin" fill="none" viewBox="0 0 32 32">
  <g fill="var(--fg-color, #2A2859)">
    <path d="M16 16.1a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
    <path
      d="M16 0c3.392 0 6.784 1.294 9.373 3.882 5.176 5.177 5.176 13.569 0 18.745L16 32l-9.373-9.373c-5.176-5.176-5.176-13.569 0-18.745A13.217 13.217 0 0 1 16 0Zm0 29.172 7.958-7.959c4.389-4.388 4.389-11.528 0-15.917A11.182 11.182 0 0 0 16 2a11.179 11.179 0 0 0-7.958 3.297c-4.388 4.388-4.388 11.529 0 15.917L16 29.172Z" />
  </g>
</symbol><symbol id="menu" fill="none" viewBox="0 0 32 32">
  <path fill="var(--fg-color, #2A2859)" d="M2 6h28v2H2V6Zm0 9h28v2H2v-2Zm0 9h28v2H2v-2Z" />
</symbol><symbol id="minimize" fill="none" viewBox="0 0 32 32">
  <path fill="var(--fg-color, #2A2859)"
    d="M12 4h-2v6H4v2h8V4Zm8 16v8h2v-6h6v-2h-8Zm0-16v8h8v-2h-6V4h-2Zm-8 16H4v2h6v6h2v-8Z" />
</symbol><symbol id="new-window-small" fill="none" viewBox="0 0 32 32">
  <g fill="var(--fg-color, #2A2859)">
    <path d="M23.03 21.11v2.06H8.97V9.18h2.06v-2H8.97v-.01h-2v18h18.06v-4.06h-2Z" />
    <path d="M18 7.16v2h3.62l-7.26 7.28 1.38 1.39 7.27-7.29v3.63h2.02V7.16H18Z" />
  </g>
</symbol><symbol id="picture" fill="none" viewBox="0 0 32 32">
  <g fill="var(--fg-color, #2A2859)">
    <path d="M19.5 13.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
    <path fill-rule="evenodd"
      d="M32 3.625H0v25h32v-25Zm-2 20.69v2.31h-4.55l-5.248-4.786 3.546-3.235L30 24.315Zm0-2.709V5.625H2v14.352l8.061-7.388 8.658 7.897 5.031-4.59 6.25 5.71Zm-19.935-6.308 12.417 11.327H2V22.69l8.065-7.392Z"
      clip-rule="evenodd" />
  </g>
</symbol><symbol id="question" fill="none" viewBox="0 0 32 32">
  <g fill="var(--fg-color, #2A2859)">
    <path
      d="M15.93 7.6A4.87 4.87 0 0 1 17 17.23h-.06v1.68h-2v-3.56h1a2.88 2.88 0 1 0-2.78-3.6v.17l-2-.39a4.88 4.88 0 0 1 4.77-3.93ZM16 3a13 13 0 1 0 0 26 13 13 0 0 0 0-26Zm0-2a15 15 0 1 1 0 30 15 15 0 0 1 0-30Z" />
    <path d="M17.65 22.38a1.72 1.72 0 1 1-3.44-.02 1.72 1.72 0 0 1 3.44.02Z" />
  </g>
</symbol><symbol id="table" fill="none" viewBox="0 0 32 32">
  <path fill="var(--fg-color, #2A2859)" fill-rule="evenodd"
    d="M31.995 2H.019v28h31.976V2Zm-2 11.75V28H22V13.75h7.995Zm-9.995 0V28h-8V13.75h8ZM2.019 28h7.98V13.75H2.02V28Zm27.976-16.25V4H2.019v7.75h27.976Z"
    clip-rule="evenodd" />
</symbol></svg></div>
</template>
